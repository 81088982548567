import React from 'react';
import CountdownTimer from '../components/Timer/CountdownTimer';
import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineNavigateBefore } from 'react-icons/md';

const CounterTimer = () => {
  const navigate = useNavigate();
  return (
    <section id="timer">
      <Link to="/">
        <img
          src="/Images/profile/logo1.png"
          alt="Logo For Portfolio"
          width={56}
        />
      </Link>
      <div className="max-w-[1256px] mx-auto h-full flex flex-col gap-2">
        <h1 className="py-2 sticky flex items-center gap-2  top-0 z-10 bg-[#2c2a2a] text-4xl text-gray-500 p-4 mb-1 border-b-2  ">
          <span
            className="hover:text-gray-400 cursor-pointer w-10"
            onClick={() => navigate('/')}
          >
            <MdOutlineNavigateBefore className="hover:-translate-x-1 duration-500" />
          </span>
          The Final Countdown: Track Your Time!
        </h1>
        <div className="flex flex-col md:flex-row relative justify-between gap-2 rounded-md items-cente p-3 min-h-[80vh]">
          <CountdownTimer />
        </div>
      </div>
    </section>
  );
};

export default CounterTimer;
