import React, { useEffect, useState } from 'react';
import { TbWorldWww } from 'react-icons/tb';
import {
  FaGithub,
  FaGoogle,
  FaJava,
  FaNodeJs,
  FaReact,
  FaSass,
} from 'react-icons/fa';
import {
  SiFirebase,
  SiFlask,
  SiMongodb,
  SiNextdotjs,
  SiScikitlearn,
  SiTailwindcss,
} from 'react-icons/si';
import { Link, useLocation } from 'react-router-dom';
import { MdOutlineNavigateBefore } from 'react-icons/md';

const ChildComponents = {
  reactIcon: <FaReact size={26} />,
  tailwindIcon: <SiTailwindcss size={26} />,
  flaskIcon: <SiFlask size={26} />,
  nextIcon: <SiNextdotjs size={26} />,
  firebaseIcon: <SiFirebase size={26} />,
  sklearnIcon: <SiScikitlearn size={26} />,
  javaIcon: <FaJava size={26} />,
  googleIcon: <FaGoogle size={26} />,
  mongodbIcon: <SiMongodb size={26} />,
  nodeIcon: <FaNodeJs size={26} />,
  sassIcon: <FaSass size={26} />,
};

function ProjectDetails() {
  const location = useLocation();
  const project = location.state.project;
  console.log(project);

  const [isOnline, setIsOnline] = useState(false);
  useEffect(() => {
    document.getElementById('project').scrollIntoView({
      behavior: 'smooth',
    });
    if (project.onlineLink) {
      setIsOnline(true);
    }
  }, [project.onlineLink]);

  return (
    <section id="project" className="md:h-screen">
      <Link to="/">
        <img
          src="/Images/profile/logo1.png"
          alt="Logo For Portfolio"
          width={56}
        />
      </Link>
      <div
        id={project.id}
        className="max-w-[1256px] mx-auto h-full flex flex-col gap-2"
      >
        <h1 className="py-2 sticky flex items-center gap-2  top-0 z-10 bg-[#2c2a2a] text-4xl text-gray-500 p-4 mb-1 border-b-2  ">
          <span
            className="hover:text-gray-400 cursor-pointer w-10"
            onClick={() => window.history.back()}
          >
            <MdOutlineNavigateBefore className="hover:-translate-x-1 duration-500" />
          </span>
          {project.name}
        </h1>
        <div className="flex flex-col md:flex-row relative justify-between gap-2 rounded-md items-cente p-3">
          <div className="grid gap-3">
            <img
              src={project.imgSrc}
              alt="ProjectImage"
              className="md:w-[600px] w-[300px] aspect-[16/9] object-contain rounded-md"
            />
            <div className="p-2 flex flex-col gap-4">
              <h1 className="text-2xl text-[#5a7b8e] font-semibold">
                Description
              </h1>
              <p className="text-justify md:w-[800px]">{project.description}</p>
            </div>
          </div>
          <div className="flex md:w-96 md:absolute top-5 right-5 p-4 flex-col gap-2">
            <div className="flex  gap-2 justify-between">
              <h1>Technologies Used</h1>
              <div className="flex gap-1">
                {project.childComponentTypes &&
                  project.childComponentTypes.map((type, index) => (
                    <span key={index}>
                      {/* Assuming ChildComponents is an object with component types */}
                      {ChildComponents[type]}
                    </span>
                  ))}
              </div>
            </div>
            <div className="flex justify-between">
              <h1>Duration</h1>
              <p>{project.projectDuration}</p>
            </div>
            <div className="flex justify-between">
              <h1>Team Size</h1>
              <p>{project.teamSize}</p>
            </div>
            <div className="flex justify-between">
              <h1>Role</h1>
              <p>{project.role}</p>
            </div>
            <div className="flex gap-3">
              {project.codeLink && (
                <Link
                  to={project.codeLink}
                  target="_blank"
                  className="bg-[#304f61] flex gap-1 items-center mt-2 text-white px-4 py-2 rounded-md hover:bg-[#32617d] duration-500"
                >
                  <FaGithub size={26} />
                  Code
                </Link>
              )}
              {isOnline ? (
                <Link
                  to={project.onlineLink}
                  target="_blank"
                  className="bg-[#304f61] flex gap-1 items-center mt-2 text-white px-4 py-2 rounded-md hover:bg-[#32617d] duration-500"
                >
                  <TbWorldWww size={26} />
                  Web
                </Link>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectDetails;
