import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import FooterBox from './components/FooterBox';
import ProjectDetails from './pages/ProjectDetails';
import NoPage from './pages/NoPage';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';
import CounterTimer from './pages/CounterTimer';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    fallbackLng: 'en',
    detection: {
      order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

const loading = <div>loading...</div>;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={loading}>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" index element={<App />} />
          <Route path="/countdown-timer" index element={<CounterTimer />} />
          <Route path="project/:id" element={<ProjectDetails />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
        <FooterBox />
      </BrowserRouter>
      <SpeedInsights />
      <Analytics />
    </React.StrictMode>
  </Suspense>
);
