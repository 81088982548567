import React, { useState, useEffect } from 'react';
import { MdOutlineAutoDelete } from 'react-icons/md';
import { TbClockEdit } from 'react-icons/tb';

const CountdownTimer = () => {
  const [timers, setTimers] = useState(() => {
    const savedTimers = localStorage.getItem('timers');
    return savedTimers ? JSON.parse(savedTimers) : [];
  });
  const [newTimer, setNewTimer] = useState({ title: '', datetime: '' });
  const [editingIndex, setEditingIndex] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedTimers = timers.map((timer) => {
        const now = new Date().getTime();
        const distance = new Date(timer.datetime).getTime() - now;

        if (distance <= 0) {
          timer.remainingTime = 'Time is up!';
          timer.timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
        } else {
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (distance % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);

          timer.remainingTime = `${days}d ${hours}h ${minutes}m ${seconds}s`;
          timer.timeLeft = { days, hours, minutes, seconds };
        }
        return timer;
      });

      // Sort timers by remaining time (ascending order)
      updatedTimers.sort((a, b) => {
        const aDistance = new Date(a.datetime).getTime() - new Date().getTime();
        const bDistance = new Date(b.datetime).getTime() - new Date().getTime();
        return aDistance - bDistance;
      });

      setTimers(updatedTimers);
      localStorage.setItem('timers', JSON.stringify(updatedTimers));
    }, 1000);

    return () => clearInterval(interval);
  }, [timers]);

  const handleAddTimer = () => {
    if (!newTimer.title || !newTimer.datetime) {
      alert('Please fill in both title and datetime fields');
      return;
    }

    const now = new Date().getTime();
    const targetTime = new Date(newTimer.datetime).getTime();
    const distance = targetTime - now;

    const defaultTimeLeft =
      distance > 0
        ? {
            days: Math.floor(distance / (1000 * 60 * 60 * 24)),
            hours: Math.floor(
              (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            ),
            minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((distance % (1000 * 60)) / 1000),
          }
        : { days: 0, hours: 0, minutes: 0, seconds: 0 };

    let updatedTimers;
    if (editingIndex !== null) {
      updatedTimers = timers.map((timer, index) =>
        index === editingIndex
          ? { ...newTimer, timeLeft: defaultTimeLeft, remainingTime: '' }
          : timer
      );
      setEditingIndex(null);
    } else {
      updatedTimers = [
        ...timers,
        { ...newTimer, timeLeft: defaultTimeLeft, remainingTime: '' },
      ];
    }

    setTimers(updatedTimers);
    localStorage.setItem('timers', JSON.stringify(updatedTimers));
    setNewTimer({ title: '', datetime: '' });
  };

  const handleEdit = (index) => {
    setNewTimer({
      title: timers[index].title,
      datetime: timers[index].datetime,
    });
    setEditingIndex(index);
  };

  const handleDelete = (index) => {
    const updatedTimers = timers.filter((_, i) => i !== index);
    setTimers(updatedTimers);
    localStorage.setItem('timers', JSON.stringify(updatedTimers));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewTimer({ ...newTimer, [name]: value });
  };

  return (
    <div className="w-full">
      <h2 className="text-xl text-center mb-4">
        Create a Timer for Your Upcoming Event
      </h2>
      <div className="flex flex-col center gap-4 mb-4 justify-center items-center p-4 md:flex-row">
        <div className="w-full max-w-sm min-w-[200px]">
          <div className="relative">
            <input
              type="text"
              name="title"
              value={newTimer.title}
              onChange={handleChange}
              className="peer w-full bg-transparent placeholder:text-slate-400 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow"
            />
            <label className="absolute cursor-text bg-[#2c2a2a] px-1 transition-all transform origin-left -top-2 left-2.5 text-xs text-slate-400 scale-90">
              Event Title
            </label>
          </div>
        </div>

        <div className="w-full max-w-sm min-w-[200px]">
          <div className="relative">
            <input
              type="datetime-local"
              name="datetime"
              value={newTimer.datetime}
              onChange={handleChange}
              className="peer w-full bg-transparent placeholder:text-slate-400  text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow"
            />
            <label className="absolute cursor-text bg-[#2c2a2a] px-1 transition-all transform origin-left -top-2 left-2.5 text-xs text-slate-400 scale-90">
              Event Date
            </label>
          </div>
        </div>
        <button
          onClick={handleAddTimer}
          className="bg-[#304f61] flex gap-1 items-center text-white px-4 py-2 rounded-md hover:bg-[#32617d] duration-500"
        >
          {editingIndex !== null ? 'Update Timer' : 'Add Timer'}
        </button>
      </div>

      {timers.length === 0 ? (
        <p className="text-xl text-center mb-10">No countdown added yet.</p>
      ) : (
        <div className="w-full mt-8">
          <h2 className="text-xl text-center mb-4">
            Currently, {timers?.length} Countdown
            {timers.length > 1 ? 's are ' : ' is'} running..
          </h2>
          {timers.map((timer, index) => (
            <div
              key={index}
              className="w-full flex py-3 justify-center gap-4 items-center"
            >
              <div>
                <h3 className="text-lg">{timer.title}</h3>
                <div className="flex gap-6 justify-between flex-col items-center md:flex-row">
                  <div className="flex gap-[1px] border overflow-hidden bg-slate-200 rounded-md">
                    <div className="flex flex-col p-4 md:px-12 bg-[#2c2a2a] text-center w-[100px] md:w-[200px]">
                      <p className="text-[54px] md:text-[92px]">
                        {timer.timeLeft.days}
                      </p>
                      <p className="text-sm">Days</p>
                    </div>
                    <div className="flex flex-col p-4 md:px-12 bg-[#2c2a2a] text-center w-[100px] md:w-[200px]">
                      <p className="text-[54px] md:text-[92px]">
                        {timer.timeLeft.hours}
                      </p>
                      <p className="text-sm">Hours</p>
                    </div>
                    <div className="flex flex-col p-4 md:px-12 bg-[#2c2a2a] text-center w-[100px] md:w-[200px]">
                      <p className="text-[54px] md:text-[92px]">
                        {timer.timeLeft.minutes}
                      </p>
                      <p className="text-sm">Minutes</p>
                    </div>
                    <div className="flex flex-col p-4 md:px-12 bg-[#2c2a2a] text-center w-[100px] md:w-[200px]">
                      <p className="text-[54px] md:text-[92px]">
                        {timer.timeLeft.seconds}
                      </p>
                      <p className="text-sm">Seconds</p>
                    </div>
                  </div>
                  <div className="flex md:flex-col gap-2">
                    <button
                      className="p-2  border border-slate-200 text-white rounded-md"
                      onClick={() => handleEdit(index)}
                    >
                      <TbClockEdit className="text-xl" />
                    </button>
                    <button
                      className="p-2 border border-slate-200 text-white rounded-md"
                      onClick={() => handleDelete(index)}
                    >
                      <MdOutlineAutoDelete className="text-xl" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="text-center my-6 max-w-[60%] mx-auto ">
        Stay organized and on track with our countdown feature! Whether you're
        managing a deadline, event, or project, this tool helps you keep a close
        eye on how much time you have left, ensuring you never miss an important
        moment. Perfect for boosting productivity and managing time effectively!
      </div>
    </div>
  );
};

export default CountdownTimer;
